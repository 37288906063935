const fonts = {
  sansSerif: "Inter, Helvetica, Arial, sans-serif",
}
const heading = {
  fontFamily: "heading",
  color: "navy"
}

export default {
  breakpoints: ['34em', '44em', '54em', '70em'],
  buttons: {
    primary: {
      display: "block",
      bg: "#ECF7FF",
      color: "#28518E",
      borderRadius: "5px",
      p: "1rem",
      textDecoration: "none",
      fontWeight: "500",
      opacity: ".85",
      cursor: "pointer",
      transition: "opacity .2s",
      "&:hover": {
        opacity: "1"
      }
    },
    form: {
      p: "1rem 2rem",
      bg: "navy",
      color: "white",
      opacity: ".85",
      cursor: "pointer",
      transition: "opacity .2s",
      "&:hover": {
        opacity: "1"
      }
    }
  },
  colors: {
    background: "#fff",
    navy: "#112C55",
    navy90: "rgba(17, 44, 85, .9)",
    navy75: "rgba(17, 44, 85, .75)",
    slate: "#697685",
    skyBlue: "#E9F6FF"
  },
  fonts: {
    ...fonts,
    body: fonts.sansSerif,
    heading: fonts.sansSerif
  },
  fontSizes: [
    "0.9rem",
    "1rem",
    "1.1rem",
    "1.225rem",
    "1.44rem",
    "1.728rem",
    "2.074rem",
    "2.488rem",
  ],
  fontWeights: {
    regular: "400",
    medium: "500",
    semibold: "600",
    bold: "700"
  },
  links: {
    default: {
      textDecoration: "none",
      color: "navy75",
      transition: "color .2s",
      "&:hover, &.active": {
        color: "navy",
        opacity: 1,
      }
    },
    paginationItem: {
      color: "#999",
      textDecoration: "none",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      width: "2.5rem",
      height: "2.5rem",
      border: "2px solid #eee",
      borderRadius: "2px",
      mr: 3,
      "&:last-child": {
        mr: 0
      }
    }
  },
  text: {
    heading: {
      fontFamily: "sansSerif",
      color: "navy",
    },
    label: {
      fontWeight: "semibold",
      fontSize: 0,
      textTransform: "uppercase",
      letterSpacing: ".5px"
    },
    pill: {
      display: "inline-block",
      fontSize: 0,
      px: ".5rem",
      py: ".15rem",
      borderRadius: "20px",
      bg: "skyBlue",
      color: "navy90",
      fontWeight: "medium"
    }
  },
  styles: {
    root: {
      fontFamily: "sansSerif",
      fontSize: ["16px", "17px", "18px"],
      h1: {
        ...heading,
        fontSize: 6,
        fontWeight: "bold",
      },
      h2: {
        ...heading,
        fontSize: 5,
        fontWeight: "semibold",
      },
      color: "slate",
      lineHeight: "1.5"
    },
  },
  layout: {
    wrapper: {
      small: {
        width: "90%",
        mx: "auto",
        maxWidth: "600px"
      },
      medium: {
        width: ["90%", "85%", "80%", "75%", "65%"],
        maxWidth: "800px",
        mx: "auto",
        "img": {
          width: "100%"
        }
      },
      large: {
        width: "90%",
        maxWidth: "1200px",
        mx: "auto",
        "img": {
          width: "100%"
        }
      }
    }
  }
}
