// /** @jsx jsx */
// import { jsx, Box } from "theme-ui"
// import { Global } from "@emotion/core"
// import React from "react"
import "typeface-inter"

// export const wrapPageElement = ({ element, props: { location } }) => {
//   return (
//     <Box location={location}>
//       <Global
//         styles={theme => ({
//           html: {
//             fontSize: ["16px", "18px", "22px"],
//           },
//         })}
//       />
//       {element}
//     </Box>
//   )
// }
