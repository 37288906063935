// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-alps-js": () => import("./../../../src/pages/alps.js" /* webpackChunkName: "component---src-pages-alps-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-templates-alp-js": () => import("./../../../src/templates/alp.js" /* webpackChunkName: "component---src-templates-alp-js" */),
  "component---src-templates-post-list-js": () => import("./../../../src/templates/postList.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-prismic-post-js": () => import("./../../../src/templates/prismicPost.js" /* webpackChunkName: "component---src-templates-prismic-post-js" */)
}

